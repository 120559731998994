import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../../components/layout'
import Hero from '../../components/hero-image'
import HeroBar from '../../components/hero-bar'
import ProductOptions from '../../components/product-options'
import ProductFeatures from '../../components/product-features'

const Sliding = () => (
<Layout>
    <Helmet title="Sliding Doors | Effortless smooth gliding action | Rudd Joinery">
      <meta name="description" content="" />      
    </Helmet>
    <Hero heroImage="sliding-doors" heroId="hero" nextId="description">
      <h1>Sliding</h1>
      <h2>Effortless smooth gliding action</h2>
    </Hero>
    <div className="section" id="description">
      <div className="wrapper">
        <div
          className="story text-block"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Our <strong>Performance Flush</strong> timber casement windows are
            manufactured using the very best quality engineered timber and
            hardware to provide an excellent combination of thermal performance
            and secure design incorporating dual weather seals to further
            prevent weather ingress in exposed locations.
          </p>
        </div>
      </div>
    </div>
    <ProductFeatures>
      <div className="cta-bar sticky ovalroomblue">
        <div className="expanded">
          <h3>Our performance flush features</h3>
          <ul>
            <li>Dual high performance weather seals</li>
            <li>Multi-point espag Locking</li>
            <li>Egress easy clean hinges</li>
            <li>24mm toughened argon filled double glazing</li>
          </ul>
        </div>
      </div>
      <div className="feature-bar">
        <div className="block c__1" />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1" />
      </div><div className="feature-bar">
        <div className="block c__1" />
      </div>
      <div className="feature-bar lightgrey">
        <div className="block c__1" />
      </div>
      <div className="cta-bar ovalroomblue">
        <div className="expanded">
          <p>
            Our <strong>Performance Flush</strong> windows can be installed
            alongside our Stormproof windows and doors, as all our mouldings are
            complimented across our product range
          </p>
        </div>
      </div>
    </ProductFeatures>
    <HeroBar imageName="sliding-doors" />
    <div className="section ovalroomblue">
      <div className="wrapper">
        <div className="story text-block">
          <h2 className="heading">Performance flush options</h2>
        </div>
        <ProductOptions ProductName="Performance flush" />
      </div>
    </div>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading">Performance flush</h2>
          <p>
            Our bespoke <strong>Performance Flush</strong> window can be
            manufactured to your specific requirements. If you need help, please
            do not hesitate to contact us on <strong>01485 529136</strong> or
            fill out our <Link to="/contact-us#contact-us">contact form</Link>.
          </p>
          <p>
            <Link to="/contact-us" className="cta">
              Contact us
            </Link>
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Sliding